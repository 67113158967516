// === IMPORTS ===
import $ from 'jquery'
import { firebase, db, stations } from './firebase'

// === YOUR FIREBASE CODE ===

firebase.auth().onAuthStateChanged(user => {
  // Wenn kein Nutzer eingeloggt ist gibt firebase.auth().currentUser den Wert null zurück.
  // Wir überprüfen hier wenn ein Nutzer vorhanden ist möchten wir für diesen Nutzer gerne
  // seine Stationen herunterladen
  if (firebase.auth().currentUser === null) {
    // Wenn kein Nutzer eingeloggt ist oder der Nutzer sich ausgeloggt hat
    // lösche alle stationen von der Seite
    $('#content').html('Bitte loggen Sie sich ein!')
  } else {
    // content zurücksetzen
    $('#content').html('')

    // Lade alle capabilities herunter.
    db.collection('capabilities')
      .get()
      .then(caps => {
        const capabilities = {}

        // Speichere die informationen für jede capability in das capabilities objekt.
        caps.forEach(cap => {
          capabilities[cap.id] = cap.data()
        })

        // Lade alle stationen herunter
        stations
          .where('owner', '==', user.uid)
          .get()
          .then(docs => {
            console.log('Recieved Snapshot')

            // For each station inside the collection print its id and its location
            docs.forEach(doc => {
              const stationDiv = $('<div class="station"></div>')

              var available = false

              stations
                .doc(doc.id)
                .collection('datapoints')
                .limit(1)
                .orderBy('timestamp', 'desc')
                .onSnapshot(datapoints => {
                  const dp = datapoints.docs[0]

                  if (dp === undefined) {
                    const titleDiv = $('<p class="title">' + 'Keine Daten vorhanden' + '</p>')
                    stationDiv.append(titleDiv)
                    return
                  }

                  const timestamp = dp.data().timestamp
                  const data = dp.data().data

                  available = true

                  const titleDiv = $('<p class="title">' + data['location'] + '</p>')
                  const datapointDiv = $('<div class="datapoint"></div>')

                  for (var [key, value] of Object.entries(data)) {
                    if (key === 'location') continue
                    const capability = capabilities[key]

                    if (capability.type === 'Number') {
                      value = Math.round(value)
                    }

                    datapointDiv.append(
                      '<p class="capabilityLabel">' + capability.name.de + ': ' + String(value) + '</p>'
                    )
                  }

                  stationDiv.html('')
                  stationDiv.append(titleDiv)
                  stationDiv.append(datapointDiv)

                  if (available) {
                    $('#content').append(stationDiv)
                  }
                })
            })
          })
      })
  }
})
